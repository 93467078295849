import {
  SUBDIVISION_SOYBEAN_ID,
  SUBDIVISION_CORN_ID,
  SUBDIVISION_CRUSHING_ID,
  SUBDIVISION_WHEAT_ID,
  SUBDIVISION_FERTILIZER_ID,
  SUBDIVISION_COTTON_ID,
  SUBDIVISION_COFFEE_ID,
  SUBDIVISION_SUGAR_MILLS_ID,
  SUBDIVISION_SUGAR_TRADING_ID,
  SUBDIVISION_COTTON_SEEDS_ID,
  SUBDIVISION_ETHANOL_ID,
  SUBDIVISION_ENERGY_ID,
  OPERATION_PREFIN_ID,
  OPERATION_PREPAY_ID,
  OPERATION_MTM_ID,
  OPERATION_DELIVERED_PRODUCT_ADVANCE_ID,
  OPERATION_INTERN_MARKET_ID,
  OPERATION_PREFIN_SUGAR_MILLS_ID,
  OPERATION_FERTILIZER_SUPPLIERS_ID,
  DIVISION_GEO_ID,
  DIVISION_SOFT_ID,
  OPERATION_PREPAY_COTTON_ID,
} from './constants';

export const divisions: any = {
  [DIVISION_GEO_ID]: [
    SUBDIVISION_SOYBEAN_ID,
    SUBDIVISION_CORN_ID,
    SUBDIVISION_CRUSHING_ID,
    SUBDIVISION_WHEAT_ID,
    SUBDIVISION_FERTILIZER_ID,
  ],
  [DIVISION_SOFT_ID]: [
    SUBDIVISION_COTTON_ID,
    SUBDIVISION_COFFEE_ID,
    SUBDIVISION_SUGAR_MILLS_ID,
    SUBDIVISION_SUGAR_TRADING_ID,
    SUBDIVISION_COTTON_SEEDS_ID,
    SUBDIVISION_ETHANOL_ID,
    SUBDIVISION_ENERGY_ID,
  ],
};

export const subDivisions: any = {
  [SUBDIVISION_SOYBEAN_ID]: [
    OPERATION_PREFIN_ID,
    OPERATION_PREPAY_ID,
    OPERATION_MTM_ID,
    OPERATION_DELIVERED_PRODUCT_ADVANCE_ID,
    OPERATION_INTERN_MARKET_ID,
  ],
  [SUBDIVISION_CORN_ID]: [
    OPERATION_PREFIN_ID,
    OPERATION_PREPAY_ID,
    OPERATION_MTM_ID,
    OPERATION_DELIVERED_PRODUCT_ADVANCE_ID,
    OPERATION_INTERN_MARKET_ID,
  ],
  [SUBDIVISION_COTTON_ID]: [
    OPERATION_PREFIN_ID,
    OPERATION_PREPAY_COTTON_ID,
    OPERATION_MTM_ID,
    OPERATION_INTERN_MARKET_ID,
  ],
  [SUBDIVISION_COTTON_SEEDS_ID]: [OPERATION_PREPAY_ID, OPERATION_MTM_ID, OPERATION_INTERN_MARKET_ID],
  [SUBDIVISION_CRUSHING_ID]: [OPERATION_MTM_ID, OPERATION_INTERN_MARKET_ID],
  [SUBDIVISION_WHEAT_ID]: [OPERATION_MTM_ID, OPERATION_INTERN_MARKET_ID],
  [SUBDIVISION_COFFEE_ID]: [OPERATION_MTM_ID, OPERATION_INTERN_MARKET_ID, OPERATION_PREFIN_ID],
  [SUBDIVISION_SUGAR_MILLS_ID]: [OPERATION_PREFIN_SUGAR_MILLS_ID],
  [SUBDIVISION_SUGAR_TRADING_ID]: [OPERATION_MTM_ID, OPERATION_PREFIN_ID],
  [SUBDIVISION_ETHANOL_ID]: [OPERATION_MTM_ID, OPERATION_INTERN_MARKET_ID],
  [SUBDIVISION_ENERGY_ID]: [OPERATION_MTM_ID, OPERATION_INTERN_MARKET_ID],
  [SUBDIVISION_FERTILIZER_ID]: [OPERATION_INTERN_MARKET_ID, OPERATION_FERTILIZER_SUPPLIERS_ID],
};
